<template>
<div class="chat__wrapper" ref='messageDisplay'>
        <template v-for="(item, index) in chat_messages" :key="index">
          <div class="chat__message" v-if="current_appointment_therapist && current_appointment_therapist.id == item.senderId">
             <div class="chat-img me" >
             <div class="us-img">
                <span><img src="../assets/images/fq-2.svg" v-if="!chat_messages[index].image_url"></span>
                <span><img :src="chat_messages[index].image_url" v-if="chat_messages[index].image_url"></span>
                </div>
                <div class="chat-nm">
                <h3>{{chat_messages[index].sender_name}}</h3>
                <h5>Online</h5>
                </div>
             </div>
             <p>{{chat_messages[index].message}}</p>
          </div>
          <div class="chat__message chat__message-own"  v-if="user && user.id == item.senderId">
             <div class="chat-img next">
              <div class="us-img">
                <span><img src="../assets/images/fq-2.svg" v-if="!chat_messages[index].image_url"></span>
                <span><img :src="chat_messages[index].image_url" v-if="chat_messages[index].image_url"></span>
                </div>
                <div class="chat-nm">
                <h3>{{chat_messages[index].sender_name}}(You)</h3>
                <h5>Online</h5>
                </div>
             </div>
             <p>{{chat_messages[index].message}}</p>
          </div>
          <div class="chat__message chat__message-three" v-if="current_appointment_attendee && current_appointment_attendee.id == item.senderId">
             <div class="chat-img me">
             <div class="us-img">
                <span><img src="../assets/images/fq-2.svg" v-if="!chat_messages[index].image_url"></span>
                <span><img :src="chat_messages[index].image_url" v-if="chat_messages[index].image_url"></span>
                </div>
                <div class="chat-nm">
                <h3>{{chat_messages[index].sender_name}}</h3>
                <h5>Online</h5>
                </div>
             </div>
             <p>{{chat_messages[index].message}}</p>
          </div>
        </template>
</div>
</template>
<script>
export default {
  props:['chat_messages','current_appointment_details','user','message_count','current_appointment_therapist','current_appointment_attendee'],
  data() {
        return {
               background_color:"rgb(0 0 0 / 44%)",
               display_color:"block",
               display_no_color:"none",
               current_message:"",
           };
  },
  mounted(){
   console.log("Hello",this.chat_messages);
  },
  watch: {
    message_count: function() {
   console.log("Hello",this.message_count);
     setTimeout(() => {
       this.scrollData();
      },200);
    }
  },
  methods:{
    hideOfflineModal(){
       // console.log("hide modal",true);
         this.$emit('hideChatModal',{'closer':1,'load':0});
    },
    scrollData(){
        let messageDisplay = this.$refs.messageDisplay;
        messageDisplay.scrollTop = (messageDisplay.scrollHeight + 120);
    }
  }
}
</script>
<style scoped>
.chat__message.chat__message-three p {
background: #2bbf763d;
}
.chat-img {
display: flex;
align-items: center;
margin-bottom: 10px;
flex-wrap: wrap;
}
.chat-img img {
max-width: 30px;
max-height: 30px;
min-width: 35px;
min-height: 35px;
border-radius: 100px;
margin-right: 10px;
}
.chat-img h3 {
font-size: 15px;
margin: 0 0 0px;
}
.chat-img h5{
width: auto;
font-size: 12px;
color: #9e9e9e;
padding-left: 0;
margin-top: 0;
margin-left: auto;
position: absolute;
right: 0;
}
.chat-nm {
display: flex;
width: 80% !important;
position: relative;
}
.chat-img.order-chng {
justify-content: flex-end;
}
.chat-img.order-chng span img {
margin-left: 10px;
margin-right: 0;
}
.chat-img.order-chng h5 {
margin-top: -10px;
margin-bottom: 0;
text-align: right;
}
.chat-img.order-chng h3 {
text-align: right;
}
.us-img {
min-width: 45px;
}
.chat-img.next div {
width: 11%;
}
.chat__wrapper::-webkit-scrollbar {
width: 7px;
}
.chat__wrapper::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px grey; 
border-radius: 10px;
}
.chat__wrapper::-webkit-scrollbar-thumb {
background: #2BBF76; 
border-radius: 10px;
}
.chat__wrapper::-webkit-scrollbar-thumb:hover {
background: #2BBF76; 
}
.chat {
padding-bottom: 52px;
}
.chat-img.me h5 {
left: 0;
right: inherit;
}
.chat-img.me .chat-nm {
justify-content: flex-end;
text-align: right;
margin-left: auto;
}
.chat-img.me .us-img {
order: 2;
text-align: right;
margin-left: auto;
}
</style>
