<template>
<div class="modal fade account-delete" id="add-payment" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content" style="min-width: 1000px;margin-left: -65%;min-height: 368px;">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closeBtn"><i class="fas fa-times"></i></button>
      </div>
      <div class="modal-body log-out-user">
        <div class="row">
           <div class="col-md-6">
               <VuePaycard
                :inputFields="inputFields"
                :valueFields="valueFields"
                :labels="labels"
                :isCardNumberMasked="isCardNumberMasked"
                :hasRandomBackgrounds="hasRandomBackgrounds"
                :backgroundImage="backgroundImage"
                :setType="setType"
                @get-type="changeType"
              />
           </div>
           <div class="col-md-6">
              <div class="succes-modal">
                <div class="add-new-payment">
                  <form class="login-from card-item">
                    <div class="row">
                       <div class="form-group col-md-6">
                          <div class="card-input">
                            <label for="cardNumber" class="card-input__label">Card Number</label>
                            <input
                              type="tel"
                              :id="inputFields.cardNumber"
                              title="Number"
                              class="card-input__input"
                              :value="valueFields.cardNumber"
                              @input="changeNumber"
                              data-card-field
                              autocomplete="off"
                              :maxlength="cardNumberMaxLength"
                            />
                          </div>
                        </div>
                        <div class="form-group col-md-6">
                          <div class="card-input">
                            <label for="cardName" class="card-input__label">Card Holder</label>
                            <input
                              type="text"
                              :id="inputFields.cardName"
                              title="Name"
                              v-letter-only
                              class="card-input__input"
                              :value="valueFields.cardName"
                              @input="changeName"
                              data-card-field
                              autocomplete="off"
                            />
                          </div>
                        </div>
                    </div>
                   
                    <div class="row">
                      <div class="col-md-6">
                       <div class="form-group ">
                          <label for="cardMonth" class="card-input__label" aria-label="Expiration Date">Expiration Date</label>
                          <select
                            class="card-input__input -select"
                            :id="inputFields.cardMonth"
                            aria-label="Card Month"
                            title="Month"
                            v-model="valueFields.cardMonth"
                            data-card-field
                            style="width:50%;"
                          >
                            <option value disabled selected>Month</option>
                            <option
                              v-bind:value="n < 10 ? '0' + n : n"
                              v-for="n in 12"
                              v-bind:disabled="n < minCardMonth"
                              v-bind:key="n"
                            >{{generateMonthValue(n)}}</option>
                          </select>
                          <select
                            class="card-input__input -select"
                            :id="inputFields.cardYear"
                            aria-label="Card year"
                            title="Year"
                            v-model="valueFields.cardYear"
                            data-card-field
                           style="width:50%;">
                            <option value disabled selected>Year</option>
                            <option
                              v-bind:value="$index + minCardYear"
                              v-for="(n, $index) in 30"
                              v-bind:key="n"
                            >{{$index + minCardYear}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <div class="card-input">
                            <label for="cardCvv" class="card-input__label" aria-label="Card CVV">CVV</label>
                            <input
                              type="tel"
                              title="CVV"
                              class="card-input__input"
                              v-number-only
                              :id="inputFields.cardCvv"
                              maxlength="4"
                              :value="valueFields.cardCvv"
                              @input="changeCvv"
                              data-card-field
                              autocomplete="off"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="form-check">
                      <!--   <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                          Remember Me
                        </label> -->
                      </div>
                    </div>
                    <div class="form-group">
                      <button class="cm-btn" @click.prevent="saveCardInfo"><span>Save</span></button>
                    </div>
                  </form>
                </div>
              </div>
           </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
 <script>
import { VuePaycard } from "vue-paycard";
import Auth from '../resources/Auth.js';
import Constant from '../resources/Constant.js';
export default {
     props: {
      labels: {
          cardName: 'Full Name',
          cardHolder: 'Card Holder',
          cardMonth: 'MM',
          cardYear: 'YY',
          cardExpires: 'Expires',
          cardCvv: 'CVV'
      },
      hasRandomBackgrounds: {
        type: Boolean,
        default: true
      },
      backgroundImage: {
        type: [String, Number],
        default: ''
      },
      setType: {
        type: String,
        default: ''
      },
      show_payment:{
        type:String
      }
    },
    emits:['cardSaved'],
    components: {
      VuePaycard,
    },
    directives: {
    'number-only': {
      bind (el) {
        function checkValue (event) {

          event.target.value = event.target.value.replace(/[^0-9]/g, '')
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true
          }
          event.preventDefault()
        }
        el.addEventListener('keypress', checkValue)
      }
    },
    'letter-only': {
      bind (el) {
        function checkValue (event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault()
          }
          return true
        }
        el.addEventListener('keypress', checkValue)
      }
    }
  },
    data: () => ({
      valueFields: {
        cardName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCvv: "",
      },
      inputFields: {
          cardNumber: 'v-card-number',
          cardName: 'v-card-name',
          cardMonth: 'v-card-month',
          cardYear: 'v-card-year',
          cardCvv: 'v-card-cvv'
      },
      isCardNumberMasked:false,
      minCardYear: new Date().getFullYear(),
      mainCardNumber: '',
      cardNumberMaxLength: 19,
      generatedType: '',
      modal_class:"modal fade account-delete"
    }),
    mounted(){
       console.log("show_payment",this.show_payment);
    },
    computed: {
      minCardMonth () {
        if (this.valueFields.cardYear === this.minCardYear) { return new Date().getMonth() + 1 }
        return 1
      }
    },
    watch: {
      cardYear () {
        if (this.valueFields.cardMonth < this.minCardMonth) {
          this.valueFields.cardMonth = ''
        }
      },
      show_payment(to,from){
        console.log("card list",from);
        console.log("card to",to);
      }
    },
    methods: {
      saveCardInfo(){
        // console.log("response_data e target value",event.target.value)
       // console.log("els",);
        //console.log("valueFields",this.valueFields);
        let error_state = false;
        let post_data = {};
        if(!this.valueFields.cardNumber){
          error_state = true;
          this.$swal({icon: 'error', title: "Please enter card number",background:'#cb1111',color:'#fff'});
        }
        if(!this.valueFields.cardName){
          error_state = true;
          this.$swal({icon: 'error', title: "Please enter card owner name",background:'#cb1111',color:'#fff'});
        }
        if(!this.valueFields.cardMonth){
          error_state = true;
          this.$swal({icon: 'error', title: "Please select exp. month",background:'#cb1111',color:'#fff'});
        }
        if(!this.valueFields.cardYear){
          error_state = true;
          this.$swal({icon: 'error', title: "Please select exp. year",background:'#cb1111',color:'#fff'});
        }
        if(!this.valueFields.cardCvv){
          error_state = true;
          this.$swal({icon: 'error', title: "Please enter card cvv",background:'#cb1111',color:'#fff'});
        }
       
        if(!error_state){
          post_data.card_number = this.valueFields.cardNumber.replace(/\D/g, '');
          post_data.exp_month   = this.valueFields.cardMonth.replace(/\D/g, '');
          post_data.exp_year    = this.valueFields.cardYear;
          post_data.cvc         = this.valueFields.cardCvv.replace(/\D/g, '');

          let user_card = Constant.getbaseurl()+'/add-card';
          this.axios.post(user_card,post_data)
            .then(({data}) => {
              let response_data = data.data;
              console.log("response_data",response_data);
              this.$refs.closeBtn.click();
              this.$emit("cardSaved",true);
            })
            .catch((error) => {
                if(error.response.status == 401){
                     this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                     Auth.logout();
                     this.$router.push('login');
                 }else{
                     this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                 }
            });
        }
      },
      changeName (e) {
        this.valueFields.cardName = e.target.value
      //  this.$emit('input-card-name', this.valueFields.cardName)
      },
      changeType (val) {
        this.generatedType = val
      },
      changeNumber (e) {
        this.valueFields.cardNumber = e.target.value
        const value = this.valueFields.cardNumber.replace(/\D/g, '')
        // american express, 15 digits
        if (/^3[47]\d{0,13}$/.test(value)) {
          this.valueFields.cardNumber = value
            .replace(/(\d{4})/, '$1 ')
            .replace(/(\d{4}) (\d{6})/, '$1 $2 ')
          this.cardNumberMaxLength = 17
        } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
          // diner's club, 14 digits
          this.valueFields.cardNumber = value
            .replace(/(\d{4})/, '$1 ')
            .replace(/(\d{4}) (\d{6})/, '$1 $2 ')
          this.cardNumberMaxLength = 16
        } else if (/^62[0-9]\d*/.test(value)) {
          this.valueFields.cardNumber = value
            .replace(/(\d{6})/, '$1 ')
            .replace(/(\d{6}) (\d{7})/, '$1 $2 ')
            .replace(/(\d{6}) (\d{7}) (\d{6})/, '$1 $2 $3 ')
            .replace(/(\d{5}) (\d{5}) (\d{5}) (\d{4})/, '$1 $2 $3 $4')
          this.cardNumberMaxLength = 21
        } else if (/^\d{0,16}$/.test(value)) {
          // regular cc number, 16 digits
          this.valueFields.cardNumber = value
            .replace(/(\d{4})/, '$1 ')
            .replace(/(\d{4}) (\d{4})/, '$1 $2 ')
            .replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ')
          this.cardNumberMaxLength = 19
        }
        
        if (e.inputType == "deleteContentBackward") {
          const lastChar = this.valueFields.cardNumber.substring(
            this.valueFields.cardNumber.length,
            this.valueFields.cardNumber.length - 1
          )
          
          if (lastChar == " ") {
            this.valueFields.cardNumber = this.valueFields.cardNumber.substring(
              0,
              this.valueFields.cardNumber.length - 1
            )
          }
        }
       // this.$emit('input-card-number', this.valueFields.cardNumber)
      },
      changeMonth () {
       // this.$emit('input-card-month', this.valueFields.cardMonth)
      },
      changeYear () {
        //this.$emit('input-card-year', this.valueFields.cardYear)
      },
      changeCvv (e) {
        this.valueFields.cardCvv = e.target.value
       // this.$emit('input-card-cvv', this.valueFields.cardCvv)
      },
      generateMonthValue (n) {
        return n < 10 ? `0${n}` : n
      },
      toggleMask () {
        this.isCardNumberMasked = !this.isCardNumberMasked
        if (this.isCardNumberMasked) {
          this.maskCardNumber()
        } else {
          this.unMaskCardNumber()
        }
      },
      maskCardNumber () {
        this.valueFields.cardNumberNotMask = this.valueFields.cardNumber
        this.mainCardNumber = this.valueFields.cardNumber
        const arr = this.valueFields.cardNumber.split('')
        arr.forEach((element, index) => {
          if (index > 4 && index < 14 && element.trim() !== '') {
            arr[index] = '*'
          }
        })
        this.valueFields.cardNumber = arr.join('')
      },
      unMaskCardNumber () {
        this.valueFields.cardNumber = this.mainCardNumber
      }
    }
}
</script>
<style type="text/css">
  .card-input{
    width: 200px;
    height: 68px;
  }
  .card-input__input{
    width:50%;
  }
  .card-item{
    min-height:320px;
  }
  </style>