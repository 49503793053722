<template>
<div class="col-md-4">
  <div class="right-bar-right">
    <h4><img src="../assets/images/clip.svg" alt="">ARTICLES TO READ</h4>
    <div class="header-right">
      <div class="form-group">
        <input type="text" name="" placeholder="Search…" v-model="search_bar">
        <img src="../assets/images/search-btn.svg"  @click.prevent ="getSearchText">
      </div>
    </div>
    <template v-if="articles.length">
      <div class="stress-blog wow fadeInUp animate" style="animation-duration: 1s;cursor:pointer;" v-for="(article,index) in articles" :key="index" @click.prevent="viewArticle(article)">
        <img :src="article.image" alt="" style="max-height:380px;object-fit:cover;">
        <img class="crop-tool" src="../assets/images/ic-shr-tw.svg" alt="" v-if="article.article_saved" style="cursor:pointer;">
        <img class="crop-tool" src="../assets/images/crop.svg" alt=""  v-if="!article.article_saved" style="cursor:pointer;">
        <div class="stess-overlay">
          <h4>{{article.title}}</h4>
          <p class="inner_html">{{article.description}}</p>
          <span  style="color:white;"><i class="fas fa-play-circle"></i> {{article.total_duration}} mins</span>
        </div>
      </div>
    </template>
    <template v-if="!articles.length">
        <div v-if ="is_loaded" style="animation-duration: 1s;height: 300px;text-align: center;margin-top: 30px;">
              <div style="text-align:center;">
                <div class="lds-spinner">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
        </div>
        <div class="animate" style="animation-duration: 1s;height: 300px;text-align: center;margin-top: 30px;" v-if ="!is_loaded">
           <div >No Articles Found..</div>
        </div>
    </template>
  </div>
</div>
</template>
<script>
import Auth from '../resources/Auth.js';
import Constant from '../resources/Constant.js';
export default {
  methods:{
      getData(){
                this.is_loaded = true;
                let all_therapist_articles = Constant.getbaseurl()+'/recommended-articles?pageoffset=0&pageno=1';
                 if(this.search_bar){
                  all_therapist_articles = all_therapist_articles+"&search_text="+this.search_bar;
                 }
                this.axios.get(all_therapist_articles)
                  .then(({data}) => {
                      let response_data = data.data;
                      this.articles = response_data;
                      setTimeout(() => {
                        this.is_loaded = false;
                      },300);
                  })
                  .catch((error) => {
                      if(error.response.status == 401){
                           this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                           Auth.logout();
                           this.$router.push('login');
                       }else{
                           this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                       }
                  });
      },
      viewArticle(article){
        let article_id = "article_"+article.id;
        let encryptedText = this.$CryptoJS.AES.encrypt(article_id,"naqqe@secret");
        this.$router.push({'name':'article_detail',"params": {'id':encryptedText}});
      },
      getSearchText(){
         this.is_loaded = true;
         this.getData();
      },
  },
  mounted(){
    this.is_loaded = false;
    this.search_bar = null;
    this.getData();
  },
  data(){
    return {
      articles:[],
      is_loaded:false,
      search_bar:null,
      user : window.localStorage.getItem('naqqe_user') ? JSON.parse(window.localStorage.getItem('naqqe_user')) : null, 
    }
  }
}
</script>
<style type="text/css">
.inner_html img {
  display: none !important;
}
.inner_html {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}
</style>