<template>
<div class="col-md-8">
  <div class="right-bar-left">
    <div class="shadow-block" v-if ="is_search" style="margin-bottom:30px;">
        <div class="therepy-block request-fund wow fadeInUp animate" style="margin-bottom:0px !important;animation-duration: 2s;">
             <div style="text-align:center;" >
                  <div class="loading loading06" style="height:200px;">
                    <span data-text="S">S</span>
                    <span data-text="E">E</span>
                    <span data-text="A">A</span>
                    <span data-text="R">R</span>
                    <span data-text="C">C</span>
                    <span data-text="H">H</span>
                    <span data-text="I">I</span>
                    <span data-text="N">N</span>
                    <span data-text="G">G</span>
                    <span data-text=".">.</span>
                    <span data-text=".">.</span>
                    <span data-text=".">.</span>
                  </div>
                  <div class="ter-pro-right">
                      <span style="cursor:pointer;height:50px !important;" @click.prevent="cancelSearch">Cancel Search</span>
                  </div>
              </div>
        </div>
    </div>
    <Transition name="slide-fade">
      <template v-if="naqqe_therapists.length && (current_status == 'tell_us')">
      <div class="shadow-new-block">
        <div class="therapist-main-profile">
          <div class="ter-pre-left"  v-for="(profile,index) in naqqe_therapists" :key="index">
            <img :src="profile.profile_image">
          </div>
          <p style="font-size: 20px;padding: 5px;margin-left: 15px;margin-top: 6px;margin-bottom: 4px;">{{count_online_therapists}} therapists are available</p>
        </div>
      </div>
      </template>
   </Transition>
    <div class="shadow-block">
      <template v-if="(current_status == 'tell_us')">
          <h2>What kind of therapist you are looking for?</h2>
           <div class="tell-us-block wow fadeInUp animate" style="animation-duration: 1s;">
            <img src="../assets/images/board-list.svg">
            <h4>Tell Us</h4>
            <p>How are you feeling and we will find the therapist for you</p>
            <img  class="arrow-right" src="../assets/images/arrw-right.png" alt="" style="cursor:pointer;" @click.prevent="getForward('tell_us')">
          </div>
      </template>
      <template v-if="therapy_types && therapy_types.all_types.length && (current_status == 'therapy_types')">
        <Transition name="slide-fade">
          <div>
              <div class="therepy-block therapist-profile request-fund wow fadeInUp animate" v-for="(therapy_skill,index) in therapy_types.all_types" :key="index">
                <div class="ter-pro-left">
                  <span class="therepy-image">
                    <img :src="'https://images-naqqe.s3.ap-south-1.amazonaws.com/thumbs/'+therapy_skill.therapy_type_icon" >
                  </span>
                  <div>
                    <h4>{{therapy_skill.type}}</h4>
                  </div>
                </div>
                <div class="ter-pro-right">
                  <a class ="profile_overlay" href="#"  style="cursor:pointer;" @click.prevent="selectTherapy(therapy_skill)"> <img  src="../assets/images/arrw-right.png" alt=""></a>
                </div>
              </div>
              <div class="ter-pro-right">
                  <span style="cursor:pointer;" @click.prevent="getBackward('therapy_types')">Prev</span>
              </div>
          </div>
        </Transition>
      </template>
      <h6 class="request-head new-head" v-if="appointments.length && (current_status == 'tell_us')">Recent Appointments</h6>
      <template v-if="appointments.length && (current_status == 'tell_us')">
        <div class="therapist-profile request-fund" v-for="(appointment,index) in appointments" :key="index">
          <div class="ter-pro-left">
            <img :src="appointment.therapist_profile.profile_image">
            <div>
              <h4>{{appointment.therapist_profile.name}}</h4>
              <p>{{appointment.total_sessions}} sessions   • Last on {{getDateFormat(appointment.last_session_date ?? appointment.actual_end_time)}}</p>
            </div>
          </div>
          <div class="ter-pro-right">
            <a @click.prevent="bookAgain(appointment.therapist_profile)" style="cursor:pointer;">Book Again</a>
          </div>
        </div>
      </template>
      
    </div>
  </div>
</div>
</template>
<style type="text/css">
.therapist-main-profile .ter-pre-left img {
    max-width: 50px;
    max-height: 40px;
    min-height: 48px;
    border-radius: 100px;
    -o-object-fit: cover;
    object-fit: cover;
}
.therapist-main-profile {
    display: flex;
}
.shadow-new-block {
    box-shadow: 0 0 4px 1px #00000029;
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 30px;
    overflow: hidden;
}
.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.9s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(40px);
  opacity: 0;
}
</style>
 <script>
import moment from "moment-timezone";
import Auth from '../resources/Auth.js';
import Constant from '../resources/Constant.js';
export default {
    methods:{
      getNaqqedTherapistData(){
                let already_naqqe_therapists = Constant.getbaseurl()+'/therapist/last-appointments';
                
                this.axios.get(already_naqqe_therapists)
                  .then(({data}) => {
                      let response_data = data.data;
                      this.appointments = null;
                      if(response_data && (response_data.appointments !== undefined)){
                        this.appointments      = response_data.appointments;
                        this.online_therapists = response_data.onlineTherapists;
                        this.naqqe_therapists  = response_data.profile_images;
                        this.count_online_therapists = response_data.online_therapists;
                      }
                  })
                  .catch((error) => {
                      if(error.response.status == 401){
                           this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                           Auth.logout();
                           this.$router.push('login');
                       }else{
                           this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                       }
                  });
      },
      getTherapyTypes(){
                let therapy_types = Constant.getbaseurl()+'/therapy_type/all_list';
                this.axios.get(therapy_types)
                  .then(({data}) => {
                      let response_data = data.data;
                      this.therapy_types = response_data;
                  })
                  .catch((error) => {
                      if(error.response.status == 401){
                           this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                           Auth.logout();
                           this.$router.push('login');
                       }else{
                           this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                       }
                  });
      },
      getDateFormat(date){
          if(date)
          return moment(date,"Y-M-D HH:mm:ss").utc(date).local().format('D MMM YYYY');

      },
      getForward(current_status){
        if(current_status == 'tell_us'){
          this.current_status = 'therapy_types';
        }
        if(current_status == 'prefrence'){
          this.current_status = 'prefrence';
        }
      },
      bookAgain(therapist){
        console.log(therapist);
      },
      selectTherapy(therapy){
        this.search_therapy_type = therapy.id;
        this.$router.push({
          name: 'search_therapist',
          params: { 'therapy_id': this.search_therapy_type }
        });
      },
      getBackward(current_status){
        if(current_status == 'tell_us'){
          this.current_status = 'tell_us';
        }
        
        if(current_status == 'therapy_types'){
          this.current_status = 'tell_us';
        }
        if(current_status == 'prefrence'){
          this.current_status = 'therapy_types';
        }
      },
      cancelSearch(){
        this.$emit('searchCancelled',true);
      }
    },
    emits:['searchCancelled'],
    props:['is_search'],
    mounted() {
      this.user = window.localStorage.getItem('naqqe_user') ? JSON.parse(window.localStorage.getItem('naqqe_user')) : null;
      this.is_loaded = true;
      this.search_bar = null;
      this.appointments = [];
      this.profile_images = [];
      this.naqqe_therapists = [];
      this.online_therapists = 0;
      this.count_online_therapists = 0;
      this.therapy_types = null;
      this.search_therapy_type = null;
      this.getNaqqedTherapistData();
      this.getTherapyTypes();
      console.log("user_details",this.user);
    },
    data() {
        return {
            articles:[],
            is_loaded:false,
            search_bar:null,
            appointments:[],
            naqqe_therapists:[],
            profile_images:[],
            online_therapists:0,
            count_online_therapists:0,
            therapy_types:null,
            search_therapy_type:null,
            current_status: "tell_us",
            user : window.localStorage.getItem('naqqe_user') ? JSON.parse(window.localStorage.getItem('naqqe_user')) : null, 
        }
    }
}
</script>