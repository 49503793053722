<template>
<div class="modal" ref="messageModal" id ="message-modal" :style="{'background-color':background_color,'display':display_color}">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body" style="padding:0px;">
        <div class="session-timer">
          <div class="session-timer-inner">
              <img src="../assets/images/board.svg" alt="">
              <div class="content" style="min-width:200px;min-height:80px;">
              <h4>{{ message?? "No Therapist Found." }}</h4>
              </div>
              <div class="session-btn">
                    <a href="#" class="cm-btn" @click.prevent="closeMessageModal"><span>Close</span></a>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
    props:['message','show_message_modal'],
    emits:['closeMessageModal'],
    watch:{
         show_message_modal(){
            if(this.show_message_modal){
              this.display_color = "block";
            }else{
              this.display_color = "none";
            }
         }
    },
    methods: {
        closeMessageModal(){
          this.$emit('closeMessageModal',true);
        }
    },
    mounted(){
      this.display_color = this.show_message_modal ? "block" : " none";
    },
    data(){
      return {
         background_color:"rgb(0 0 0 / 44%)",
         display_color:"none",
      }
    }

}
</script>


