<template>
<Transition name="slide-fade">
    <div class="modal modal-width" id ="show_reason_modal" :style="{'background-color':background_color,'display':'block'}" v-if="display_reason_color == 'block'">
        <div class="modal-dialog">
          <div class="modal-content">
            <!-- Modal body -->
            <div class="modal-body">
                <div class="session-timer-inner" style="text-align: center;">
                   <img src="../assets/images/issue.svg" alt="">
                   <h4>Do you wish to raise an issue?</h4>
                   <div class="rise-frm">
                    <textarea v-model="reason" placeholder="Tell us what happened…"></textarea>
                   </div>
                    <div class="session-btn">
                         <a href="#" class="cm-btn decline-btn" @click.prevent="hideReasonModal()"><span>Cancel</span></a>
                        <a href="#" class="cm-btn" @click.prevent="saveReasonModal()"><span>Ok</span></a>
                    </div>
                </div>
            </div>
          </div>
        </div>
    </div>
</Transition>
</template>
<script>
import Auth from '../resources/Auth.js';
import Constant from '../resources/Constant.js';
export default {
  props:['show_reason_modal','display_reason_color','search_modal_details'],
  watch:{
    display_reason_color:function(value, oldValue){
        console.log("value display_reason_color",value);
        console.log("oldValue display_reason_color",oldValue);
    }
  },
  data() {
        return {
               background_color:"rgb(0 0 0 / 44%)",
               reason:"",
           };
  },

  mounted(){

  },
  methods:{
    saveReasonModal(){
         console.log(this.reason,"reason")
         if(!this.reason){
                   this.$swal({icon: 'error', title: "Please provide valid reason",background:'#cb1111',color:'#fff'});
         }else{
           this.saveReasonInfo();
         }
    },
    hideReasonModal(){
         this.reason = "";
         this.$emit('closeReason',{'closer':1,'load':0});
    },
    saveReasonInfo(){

            if(this.search_modal_details) {
                let raise_issue = Constant.getbaseurl()+'/therapist/raise-concern';
                let post_data = {
                 'appointment_id':this.search_modal_details.appointment_id,
                 'therapist_id':this.search_modal_details.therapist_id,
                 'reason':this.reason,
                };
                this.axios.post(raise_issue,post_data)
                .then((/*{data}*/) => {
                  this.reason = "";
                  this.$swal({icon: 'success', title: "Issue raised to support successfully"});
                  this.$emit('closeReason',{'closer':1,'load':0});
                })
                .catch((error) => {
                   if(error.response.status !== undefined &&(error.response.status == 401)){
                       this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                       Auth.logout();
                       this.$router.push('login');
                   }else{
                       this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                   }
                });
            }else{
                  this.reason = "";
                  this.$swal({icon: 'success', title: "Issue raised to support successfully"});
                  this.$emit('closeReason',{'closer':1,'load':0});
            }
    }
  }
}
</script>
<style type="text/css" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>

