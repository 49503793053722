<template>
<div class="modal" id ="decline_reason_modal" :style="{'background-color':background_color,'display':display_decline_reason_color}">
    <div class="modal-dialog">
        <div class="modal-content">
            <!-- Modal body -->
            <div class="modal-body"  style="padding:0px;">
                <div class="session-timer">
                    <div class="session-timer-inner">
                        <img src="../assets/images/try.svg" alt="">
                        <h4>Do you wish to stop the session?</h4>
                        <p>Please note that early cancellations are<br> non-refundable.</p>
                        <div class="session-btn mt-5">
                            <a href="#" class="cm-btn white-btn" @click.prevent="hideAskModal"><span>No</span></a>
                            <a href="#" class="cm-btn" @click.prevent="showAskModal"><span>Yes</span></a>
                        </div>
                    </div>
                    <div class="session-timer-inner" v-if="reason_modal">
                        <img src="../assets/images/Group27838.png" alt="" class="mx-auto d-block mb-3">
                        <p class="internal-p">For Internal Purpose Only</p>
                        <h4 class="offline-reason">Reason?</h4>
                        <ul class="reason-list">
                            <li  v-for="decline_reason in decline_reasons" :key="decline_reason.id">
                                <label class="rad-ct">{{decline_reason.reason}}
                                    <input type="radio" v-bind:value="decline_reason.reason" name="radio" v-model="selected_reason">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                        </ul>
                        <textarea class="text-a" placeholder="Please type" v-model="current_reason"></textarea>
                        <div class="session-btn mt-0">
                            <a href="#" class="cm-btn decline-btn" @click.prevent="hideDeclineReasonModal"><span>Cancel</span></a>
                            <a href="#" class="cm-btn" @click.prevent="saveDeclineOfflineModal"><span>Save</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import Auth from '../resources/Auth.js';
import Constant from '../resources/Constant.js';
export default {
  props:['display_decline_reason_color','current_appointment_details','decline_reasons'],
  data() {
        return {
               background_color:"rgb(0 0 0 / 44%)",
               current_reason:null,
               selected_reason:null,
               ask_modal:true,
               reason_modal:false,
             //  display_color:"none",
           };
  },
  mounted(){
       this.reason_modal = false;
  },
  methods:{
    saveDeclineOfflineModal(){
            if(!this.selected_reason){
                this.$swal({icon: 'error', title:"Please Select Valid Reason",background:'#cb1111',color:'#fff'});
                return false;
            }
            let reason = null;
            let post_data = {};
            post_data.appointment_id = this.current_appointment_details.id;
            post_data.is_decline = 1;
            if(this.selected_reason == 'Other'){
              if(!this.current_reason){
                this.$swal({icon: 'error', title:"Please Enter Valid Reason",background:'#cb1111',color:'#fff'});
                return false;
              }
              post_data.reason = this.current_reason.trim();
              reason = this.current_reason.trim();
            }else{
              post_data.reason = this.selected_reason.trim();
              reason = this.selected_reason.trim();
            }

            if(!reason){

                this.$swal({icon: 'error', title:"Please Enter Valid Reason",background:'#cb1111',color:'#fff'});
                return false;

            }else{

              let call_end_url = Constant.getbaseurl()+'/end-call';
              this.axios.post(call_end_url,post_data)
                .then((/*{data}*/) => {
                    this.$swal({icon: 'success', title: "Call Ended Successfully"});
                   // let response_data = data.data;
                    this.$router.push('/');
                })
                .catch((error) => {
                   if(error.response.status == 401){
                       Auth.logout();
                       this.$router.push('login');
                   }else if(error.response.status == 501){
                   this.$swal({icon: 'success', title: "Call Ended Successfully"});
                   }else{
                       this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                       this.$router.push('/');
                   }
                });
                this.$emit('closeDeclineEnd',{'closer':1,'load':0});
            }
    },
    hideDeclineReasonModal(){
         this.ask_modal = true;
         this.reason_modal = false;
         console.log({'closer':1,'load':0})
         this.$emit('closeDeclineEnd',{'closer':0,'load':0});
    },
    hideAskModal(){
         this.ask_modal = true;
         this.reason_modal = false;
         this.$emit('closeDeclineEnd',{'closer':0,'load':0});
    },
    showAskModal(){
       this.ask_modal = false;
       this.reason_modal = true;
    }

  }
}
</script>


