<template>
<div class="modal" ref="messageModal" id ="message-modal" :style="{'background-color':background_color,'display':display_color}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body" style="padding:0px;">
                <div class="session-timer">
                    <div class="session-timer-inner">
                        <h4>Extend Your Session</h4>
                        <div class="Extend-tim">
                            <h5>Continue this session<span v-if="extend_call_time">{{ timeFromSecs(extend_call_time) }} mins</span><span v-else> 04:00 mins</span> </h5>
                            <label>{{(user && user.currency ? user.currency.symbol : '$')}}{{(selected_therapist_profile ? selected_therapist_profile.profile.call_extension_charge : '50')}}</label>
                        </div>
                        <div class="session-btn">
                            <a href="#" class="cm-btn white-btn" @click.prevent="closeMessageModal('no')"><span>Cancel</span></a>
                            <a href="#" class="cm-btn" @click.prevent="closeMessageModal('yes')"><span>Confirm</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    props:['message','show_message_modal','user','selected_therapist_profile','extend_call_time'],
    emits:['closeMessageModal'],
    watch:{
         show_message_modal(){
            if(this.show_message_modal){
              this.display_color = "block";
            }else{
              this.display_color = "none";
            }
         }
    },
    methods: {
        closeMessageModal(type){
          this.$emit('closeMessageModal',type);
        },
        timeFromSecs(seconds_data)
        {
            var seconds = seconds_data; // don't forget the second param
            var hours   = Math.floor(seconds / 3600);
            var minutes = Math.floor((seconds - (hours * 3600)) / 60);
            seconds = seconds - (hours * 3600) - (minutes * 60);
            if (hours   < 10) {hours   = "0"+hours;}
            if (minutes < 10) {minutes = "0"+minutes;}
            if (seconds < 10) {seconds = "0"+seconds;}
            var time    = minutes+':'+seconds;
            return time;
        },
    },
    mounted(){
      this.display_color =  this.show_message_modal;
      this.call_time = null;
      this.extended_call_time = null;
    },
    data(){
      return {
         background_color:"rgb(0 0 0 / 44%)",
         display_color:'none',
         call_time:null,
         extended_call_time:null,
      }
    }

}
</script>


