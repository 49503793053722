<template>
<div class="modal" ref="messageModal" id ="message-modal" :style="{'background-color':background_color,'display':display_color}">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body" style="padding:0px;">
        <div class="session-timer">
           <div class="session-timer-inner">
            <img src="../assets/images/timer.png" alt="">
            <h4 v-if="message">{{message}}</h4>
            <p v-if="total_call_time">The video call will end in {{timeFromSecs(total_call_time)}} mins</p>
            <h5>Would you like to extend the session for {{secToMins(extend_call_time)}} mins?</h5>
            <div class="session-btn">
                 <a href="#" class="cm-btn white-btn" @click.prevent="closeMessageModal('no')"><span>No</span></a>
                  <a href="#" class="cm-btn" @click.prevent="closeMessageModal('yes')"><span>Yes</span></a>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
    props:['message','show_message_modal','extend_call_time','total_call_time'],
    emits:['closeMessageModal'],
    watch:{
         show_message_modal(old,modified){
            console.log('old',old,modified);
            if(this.show_message_modal){
              this.display_color = "block";
            }else{
              this.display_color = "none";
            }
         }
    },
    methods: {
        closeMessageModal(type){
          this.$emit('closeMessageModal',type);
        },
        timeFromSecs(seconds_data)
        {
            var seconds = seconds_data; // don't forget the second param
            var hours   = Math.floor(seconds / 3600);
            var minutes = Math.floor((seconds - (hours * 3600)) / 60);
            seconds = seconds - (hours * 3600) - (minutes * 60);
            if (hours   < 10) {hours   = "0"+hours;}
            if (minutes < 10) {minutes = "0"+minutes;}
            if (seconds < 10) {seconds = "0"+seconds;}
            var time    = minutes+':'+seconds;
            return time;
        },
        secToMins(seconds_data)
        {
            var time = seconds_data/60; // don't forget the second param
            return time;
        }
    },
    mounted(){
      this.display_color = this.show_message_modal ? "block" : " none";
      this.call_time = this.total_call_time ;
      this.extended_call_time = this.extend_call_time ;
    },
    data(){
      return {
         background_color:"rgb(0 0 0 / 44%)",
         display_color:'none',
         //extend_call_time:300,
         call_time:null,
         extended_call_time:null,
      }
    }

}
</script>


