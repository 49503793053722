<template>
<div class="modal" ref="messageModal" id ="message-modal" :style="{'background-color':background_color,'display':display_color}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body" style="padding:0px;">
                 <div class="session-timer wow fadeInUp animate">
                    <div class="session-timer-inner meat-dr" v-if="show_end_call_early_modal">
                        <img src="../assets/images/thumb.svg" alt="">
                        <h4>{{ user ? user.name : ''}}, we hope you had a productive <br>session with {{ current_appointment_therapist ? current_appointment_therapist.name : "your therapist"}}</h4>
                        <p>Would you like to schedule a future session with {{ current_appointment_therapist ? current_appointment_therapist.name : "your therapist"}}</p>
                        <h6>Ongoing session is recommended for optimal result</h6>
                         <div class="session-btn">
                            <a href="#" class="cm-btn white-btn" @click.prevent="closeMessageModal('no')"><span>No</span></a>
                            <a href="#" class="cm-btn" @click.prevent="closeMessageModal('yes')"><span>Yes</span></a>
                        </div>
                    </div>
                    <div class="session-timer-inner meat-dr" v-else>
                        <img src="../assets/images/thumb.svg" alt="">
                        <h4>{{ current_appointment_therapist ? current_appointment_therapist.name : ''}}, had ended a session with you.</h4>
                        <p>You can raise issue if you are not satisfied with this session.</p>
                        <h6>Ongoing session is recommended for optimal result</h6>
                         <div class="session-btn">
                            <a href="#" class="cm-btn white-btn" @click.prevent="closeMessageModal('no')"><span>No</span></a>
                            <a href="#" class="cm-btn" @click.prevent="closeMessageModal('yes')"><span>Yes</span></a>
                        </div>
                    </div>
                  </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    props:['message','show_message_modal','user','selected_therapist_profile','extend_call_time','current_appointment_therapist','current_appointment_user'
,'current_appointment_attendee','show_end_call_early_modal'],
    emits:['closeMessageModal'],
    watch:{
         show_message_modal(){
            if(this.show_message_modal){
              this.display_color = "block";
            }else{
              this.display_color = "none";
            }
         },
         current_appointment_therapist(old,modified){
           console.log("old therapist",old);
           console.log("modified therapist",modified);
         }
    },
    methods: {
        closeMessageModal(type){
          this.$emit('closeMessageModal',type);
        },
        timeFromSecs(seconds_data)
        {
            var seconds = seconds_data; // don't forget the second param
            var hours   = Math.floor(seconds / 3600);
            var minutes = Math.floor((seconds - (hours * 3600)) / 60);
            seconds = seconds - (hours * 3600) - (minutes * 60);
            if (hours   < 10) {hours   = "0"+hours;}
            if (minutes < 10) {minutes = "0"+minutes;}
            if (seconds < 10) {seconds = "0"+seconds;}
            var time    = minutes+':'+seconds;
            return time;
        },
    },
    mounted(){
      this.display_color =  this.show_message_modal;
      this.call_time = null;
      this.extended_call_time = null;
    },
    data(){
      return {
         background_color:"rgb(0 0 0 / 44%)",
         display_color:'none',
         call_time:null,
         extended_call_time:null,
      }
    }

}
</script>


