<template>
<div class="main_modal" @click.prevent="actionModal" v-if="show_modal">
  <div class="session-timer wow fadeInUp animate" style="bottom:25% !important;" v-if="!is_loaded">
    <div class="session-timer-inner">
      <h4>Add Money</h4>
      <div class="session-amount">
        <div class="Extend-tim">
          <h5>Enter Money</h5>
        </div>
        <div class="wallet">
           <input type="text" v-model="amount" placeholder="Please Enter Money" style="width: 100%;height: 17%;border: 0px;">
        </div>
      </div>
      <div class="session-amount card-details">
        <div class="Extend-tim">
          <h5>Pay with</h5>
        </div>
        <div class="card-group-in">
          <template v-if="payment_methods.cards && payment_methods.cards.length">
            <div class="visa-list" v-for="(value,index) in payment_methods.cards" :key="index">
              <span><img src="../assets/images/visa.png"></span>
              <p>XXXXXXXXX{{value.last_four_digit}}</p>
              <label class="rad-ct">
                <input type="radio" checked v-if="(selected_card == value.id)">
                <input type="radio"  v-if="(selected_card != value.id)" >
                <span class="checkmark" @click.prevent="selectCard(value.id)"></span>
              </label>
            </div>
          </template>
          <template v-else>
             <div class="visa-list">
              <span style="text-align:center;border: 1px solid;padding: 20px;padding-right: 150px;border-radius:5px;">No Cards Added</span>
             </div>
          </template>
              <div class="session-btn">
                <a href="#" class="cm-btn" data-bs-toggle="modal" data-bs-target="#add-payment" @click = "addPayment('add_money_to_wallet')"><span>ADD NEW CARD</span></a>
              </div>
        </div>
      </div>
      <div class="session-btn">
        <a href="#" class="cm-btn" @click = "addWalletMoney"><span>ADD MONEY</span></a>
      </div>
      
    </div>
  </div>
  <div style="text-align:center;margin-top: 16% !important;" v-if="is_loaded">
      <div class="lds-spinner">
          <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
      </div>
  </div>
</div>
</template>
<script>
import moment from "moment-timezone";
import Auth from '../resources/Auth.js';
import Constant from '../resources/Constant.js';
export default {
    emits:['showAddCard','moneyAdded'],
    props:['load_data','show_make_card','show_payment'],
    watch:{
      load_data(){
         this.is_loaded             = true;
         this.selected_Card         = null;
         this.pageno                = 1;
         this.pageoffset            = 10;
         this.getSavedPaymentList();
      },
      show_payment(to,from){
        if(to && from){
          this.getSavedPaymentList();
          if(to != 'add_card')
          this.show_modal = true;
        }
      },
      show_make_card(to,from){
        if(to && from)
          this.show_modal = this.show_make_card;
      }
    },
    methods:{
      getUserProfile(){
         let user_profile = Constant.getbaseurl()+'/profile';
                this.axios.get(user_profile)
                  .then(({data}) => {
                    let response_data = data.data;
                    let user_profile  = response_data;
                    this.user         =  user_profile;
                    window.localStorage.setItem('naqqe_user', JSON.stringify(user_profile));
                    Auth.addHeader('currency-id',user_profile.currency_id);
                  })
                  .catch((error) => {
                      if(error.response.status == 401){
                           this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                           Auth.logout();
                           this.$router.push('login');
                       }else{
                           this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                       }
                  });
      },
      deleteSavedCard(value){
                this.is_loaded = true;
                let payment_method_delete = Constant.getbaseurl()+'/delete-card';
                this.axios.post(payment_method_delete,{'card_id':value.id})
                  .then(() => {
                    this.$swal({icon: 'success', title: "Your saved payment is deleted successfully"});
                    this.pageno = 1;
                    this.pageoffset  = 10;
                    this.getSavedPaymentList();
                  })
                  .catch((error) => {
                      this.is_loaded = false;
                      if(error.response.status == 401){
                           this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                           Auth.logout();
                           this.$router.push('login');
                       }else{
                           this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                       }
                  });
               
      },
      getSavedPaymentList(){
                let all_payment_method_listings = Constant.getbaseurl()+'/cards';
                    all_payment_method_listings += "?pageno="+this.pageno;
                    all_payment_method_listings += "&pageoffset="+this.pageoffset;
                    all_payment_method_listings += "&is_web=1";
                this.axios.get(all_payment_method_listings)
                  .then(({data}) => {
                    let response_data = data.data;
                    let all_payment_method_listings_data  = response_data;
                    this.payment_methods  =  all_payment_method_listings_data;
                    this.is_loaded = false;
                    console.log('payment_methods',this.payment_methods);
                  })
                  .catch((error) => {
                      if(error.response.status == 401){
                           this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                           Auth.logout();
                           this.$router.push('login');
                       }else{
                           this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                       }
                  });
               
      },
      addWalletMoney(){
        if(!this.amount){
           this.$swal({icon: 'error', title: "Please enter amount.",background:'#cb1111',color:'#fff'});
        }else if(!this.selected_card){
           this.$swal({icon: 'error', title: "Please select card.",background:'#cb1111',color:'#fff'});
        }else{
          this.is_loaded = true;
          let add_money = Constant.getbaseurl()+'/add-money-wallet';
          this.axios.post(add_money,{'card_id':this.selected_card,'payment_type':2,'amount':this.amount})
            .then(({data}) => {
              let response_data = data.data;
              this.updatePaymentWalletStatus(response_data.transaction_id);
              this.$swal({icon: 'success', title: "Please wait...."});
            })
            .catch((error) => {
                this.is_loaded = false;
                if(error.response.status == 401){
                     this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                     Auth.logout();
                     this.$router.push('login');
                 }else{
                     this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                 }
            });
        }
      },
      updatePaymentWalletStatus(transaction_id){
          let update_money_status = Constant.getbaseurl()+'/update-payment-wallet-status';
          this.axios.post(update_money_status,{'transaction_id':transaction_id,'status':'success'})
            .then(() => {
              this.is_loaded = false;
              this.$emit("moneyAdded",true);
              this.$swal({icon: 'success', title: "Money added to wallet successfully."});
              this.show_modal = false;
            })
            .catch((error) => {
                this.is_loaded = false;
                if(error.response.status == 401){
                     this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                     Auth.logout();
                     this.$router.push('login');
                 }else{
                     this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                 }
            });
      },
      selectCard(card_id){
        console.log("selected_card",card_id);
         this.selected_card = card_id;
        console.log("selected_card",this.selected_card);
      },
      unselectCard(){
         this.selected_card = null;
      },
      addPayment(type){
           this.show_modal = false;
           this.$emit('showAddCard',type)
      },
      getDateFormat(date){
          if(date)
          return moment(date,"Y-M-D HH:mm:ss").utc(date).local().format('MMM D,YYYY');
      },
      getTimeFormat(date){
          if(date)
          return moment(date,"Y-M-D HH:mm:ss").utc(date).local().format('hh:mm A');
      },
      checkExpire(value){
        let moment_month = moment().format('M');
        let moment_year  = moment().format('Y');
      //  console.log(moment_year,moment_month);
        if(moment_year <= value.exp_year){
          if(moment_month <= value.exp_month){
            return true;
          }
        }
        return false;
      },
      getMinsDuration(data){
        if(data && data.article && data.article.duration){
          let minutes = moment(data.article.duration, 'ss');
          return minutes.format('HH:mm:ss');
        }
      }
    },
    mounted() {
      this.user                  = null;
      this.is_loaded             = true;
      this.show_modal            = false;
      this.payment_methods       = [];
      this.currency              = null;
      this.selected_card         = null;
      this.pageno                = 1;
      this.pageoffset            = 10;
      this.amount                = null;
      this.getUserProfile();
      this.getSavedPaymentList();
    },
    data() {
        return {
            payment_methods:[],
            currency:null,
            selected_card:null,
            is_loaded:true,
            show_modal:false,
            amount:null,
            pageno:1,
            pageoffset:10,
            user : window.localStorage.getItem('naqqe_user') ? JSON.parse(window.localStorage.getItem('naqqe_user')) : null,
        }
    }
}
</script>
<style type="text/css">
  .main_modal {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-transition: 0.5s;
    overflow: auto;
    transition: all 0.3s linear;
  }
</style>