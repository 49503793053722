<template>
  <div class="login-page">
     <div class="login-brd">
        <div class="login-left">
            <div class="login-logo">
                <img src="../assets/images/logo.svg">
            </div>
            <div class="login-sktc wow fadeInLeft animate " style="animation-duration: 1s;">
              <img src="../assets/images/login-left.png">
            </div>
        </div>
        <div class="login-right">
          <div class="login-right-inner wow pulse animate" style="animation-duration: 1s;">
          <h3>SIGNUP</h3>
          <div class="other-logins" style="width: 820px">
            <a href="#" @click.prevent ="logInWithFacebook"> <img src="../assets/images/fb.svg">Login with Facebook</a>
            <a href="#" @click.prevent ="logInWithGoogle"> <img src="../assets/images/google.svg">Login with Google</a>
            <a href="#" @click.prevent ="logInWithApple"> <img src="../assets/images/apple.svg">Login with Apple</a>
            <a href="#" @click.prevent ="logInWithUaePass" style="background-color: white;color: black;"> <img src="../assets/images/uae.png" style="height: 20px;width: 22px;color: black;">Login with UAEpass</a>
          </div>
          <span class="or-brd">OR</span>
          <div class="login-from">
            <form>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>EMAIL</label>
                    <input type="text" v-model="user.email" placeholder="Please Enter Email">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>PASSWORD</label>
                    <input v-if="show_password" type="text" v-model="user.password" id ="password" placeholder="Please Enter Password" autocomplete="true">
                    <input v-if="hide_password" type="password" v-model="user.password" id ="password" placeholder="Please Enter Password" autocomplete="true">
                    <img src="../assets/images/eye.svg" style="cursor: pointer;" v-show="hide_password"  @mouseover="showPasswordMethod">
                    <img src="../assets/images/showpassword.png" style="cursor: pointer;height: 20px;" v-show="show_password"  @mouseleave="hidePasswordMethod">
                  </div>
                </div>
                 <div class="col-md-6">
                  <div class="form-group">
                    <label>CONFIRM PASSWORD</label>
                    <input v-if="show_confirm_password" type="text" v-model="user.confirm_password" id ="confirm_password" placeholder="Please Enter Confirm Password" autocomplete="true">
                    <input v-if="hide_confirm_password" type="password" v-model="user.confirm_password" id ="confirm_password" placeholder="Please Enter Confirm Password" autocomplete="true">
                    <img src="../assets/images/eye.svg" style="cursor: pointer;" v-show="hide_confirm_password"  @mouseover="showConfirmPasswordMethod">
                    <img src="../assets/images/showpassword.png" style="cursor: pointer;height: 20px;" v-show="show_confirm_password"  @mouseleave="hideConfirmPasswordMethod">
                  </div>
                </div>
                <div class="col-md-12 forget-pass">
                  <div class="form-group">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                       Remember Me
                      </label>
                    </div>
                     <router-link to="/forgotpassword">Forgot Password?</router-link>
                  </div>
                </div>
                  <div class="col-md-12">
                  <div class="form-group">
                    <button class="cm-btn" @click.prevent ="signup"><span>Signup</span></button>
                  </div>
                </div>
            </div>
            <router-link to="/login" class="al-linked">Already have an account? Login</router-link>
            </form>
          </div>
          </div>
        </div>
     </div>
      
  </div>
</template>
<script>
import Auth from '../resources/Auth.js';
import Constant from '../resources/Constant.js';
import jwt_decode from "jwt-decode";
export default {
    data() {
        return {
            user: {
                email:'',
                user_type: 'customer',
                password: '',
                confirm_password:'',
                is_otp_needed:0,
            },
            social_user: {
                provider_type :'email',
                provider_id:'',
                user_type: 'customer',
                provider_verification: '',
            },
            state_param:"{st=state123qwsaqw,ds=43652712}",
            google_param:"{st=statefb123qwsaqw,ds=4312567}",
            fb_redirect_url:"https://user.naqqe.app/signup",
            google_redirect_url:"https://user.naqqe.app/signup",
            apple_redirect_url:"https://user.naqqe.app/signup",
            uaepass_redirect_url:"https://user.naqqe.app/login",
            hide_password:true,
            is_registered:false,
            hide_confirm_password:true,
            show_confirm_password:false,
            show_password:false,
            location:null,
            user_name:null,
            auth_token:null,
            user_info:null,
        };
    },
    mounted() {
       let state_param = "{"+this.state_param+"}";
       let google_param = "{"+this.google_param+"}";
       this.is_registered = false;
       this.auth_token = null;
       this.user_name = null;
       this.user_info = null;
       this.location = null;

       if((this.$route.query.code !== undefined) && (this.$route.query.state !== undefined) && (this.$route.query.state == state_param)){
         this.social_user.provider_type = "facebook";
         this.getFacebookAuthToken(this.$route.query.code);
       }
       if((this.$route.query.code !== undefined) && (this.$route.query.state !== undefined) && (this.$route.query.state == google_param)){
         this.social_user.provider_type = "google";
         this.getGoogleAuthToken(this.$route.query.code);

       }
    },
    methods: {
        signup() {
            let checkFields = this.checkFields();
            if(!checkFields){
              return false;
            }
            this.checkUniqueEmail();
            
        },
        checkUniqueEmail(){
            if(!this.user.email){
              this.$swal({icon: 'error', title: "Please Enter Email",background:'#cb1111',color:'#fff'});
              return false;
            }

            let verify_register_url = Constant.getbaseurl()+'/verifyRegister';
            return this.axios.post(verify_register_url, this.user)
                .then(() => {
                        let register_url   = Constant.getbaseurl()+'/register';
                        this.axios.post(register_url, this.user)
                          .then(({data}) => {
                            let response_data = data.data;
                            this.user_name = response_data.name;
                            this.is_registered = true;
                           
                            this.auth_token = response_data.token;
                            this.user_info = response_data;
                            Auth.login(this.auth_token,this.user_info);
                            this.$swal({icon: 'success', title: "Signup Successfully"});
                            this.$router.push('/signup-profile');
                        })
                        .catch((error) => {
                          this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                        });
                })
                .catch((error) => {
                  this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                  return false;
                });
        },
        
        checkFields(){
            if(!this.user.email){
              this.$swal({icon: 'error', title: "Please Enter Email",background:'#cb1111',color:'#fff'});
              return false;
            }
            if(!this.user.password){
              this.$swal({icon: 'error', title: "Please Enter Password",background:'#cb1111',color:'#fff'});
              return false;
            }
            if(!this.user.confirm_password){
              this.$swal({icon: 'error', title: "Please Enter Confirm Password",background:'#cb1111',color:'#fff'});
              return false;
            }

            if(!(this.user.password === this.user.confirm_password)){
              this.$swal({icon: 'error', title: "Password and Confirm Password did not match",background:'#cb1111',color:'#fff'});
              return false;
            }
            return true;
        },
        showPasswordMethod(){
          this.hide_password = false;
          this.show_password = true;
        },
        hidePasswordMethod(){
           this.hide_password = true;
           this.show_password = false;
        },
        showConfirmPasswordMethod(){
          this.hide_confirm_password = false;
          this.show_confirm_password = true;
        },
        hideConfirmPasswordMethod(){
           this.hide_confirm_password = true;
           this.show_confirm_password = false;
        },
        logInWithFacebook() {
          let app_id = Constant.getFacebookId();
          var facebook_url = "https://www.facebook.com/v14.0/dialog/oauth?client_id="+app_id+"&redirect_uri="+this.fb_redirect_url+"&state={"+this.state_param+"}";
          window.location.href = facebook_url;
        },
        logInWithGoogle() {
          let client_id = Constant.getGoogleClientId();
          let scopes = "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid";
          var google_url = "https://accounts.google.com/o/oauth2/v2/auth?response_type=code&include_granted_scopes=true&client_id="+client_id+"&redirect_uri="+this.google_redirect_url+"&state={"+this.google_param+"}&scope="+scopes;
          window.location.href = google_url;
        },
        socialLogin() {
            let login_url = Constant.getbaseurl()+'/login';
                this.axios.post(login_url, this.social_user)
                  .then(({data}) => {
                      let response_data = data.data;
                      Auth.login(response_data.token,response_data);
                      this.$swal({icon: 'success', title: "Registered Successfully"});
                      this.$router.push('/');
                  })
                  .catch((error) => {
                    this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                  });
        },
        getFacebookAuthToken(code) {
            let client_id = Constant.getFacebookId();
            let client_secret = Constant.getFaceBookClientSecret();
            let redirect_uri = this.fb_redirect_url;
            let facebook_auth_url = "https://graph.facebook.com/v14.0/oauth/access_token?client_id="+client_id+"&redirect_uri="+redirect_uri+"&client_secret="+client_secret+"&code="+code;
                this.axios.get(facebook_auth_url)
                  .then(({data}) => {
                      this.social_user.provider_verification = data.access_token;
                      this.socialLogin();
                  })
                  .catch((error) => {
                    this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                  });
        },
        getGoogleAuthToken(code) {
            let client_id = Constant.getGoogleClientId();
            let client_secret = Constant.getGoogleClientSecret();
            let redirect_uri = encodeURIComponent(this.google_redirect_url);
            let grant_type ="authorization_code";
            let google_post_data = "code="+code+"&client_id="+client_id+"&client_secret="+client_secret+"&redirect_uri="+redirect_uri+"&grant_type="+grant_type;
            let google_auth_url = "https://oauth2.googleapis.com/token";
            const headers = {
                'Content-Type': 'application/x-www-form-urlencoded',
            };
                this.axios.post(google_auth_url,google_post_data,{headers: headers})
                  .then(({data}) => {
                       console.log(data);
                      this.social_user.provider_verification = data.id_token;
                      this.socialLogin(); 
                  })
                  .catch((error) => {
                    this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                  });
        },
        logInWithUaePass(){
          let client_id = "sandbox_stage";
          let scopes = "urn:uae:digitalid:profile:general";
          let uae_url = "https://stg-id.uaepass.ae/idshub/authorize?response_type=code&client_id="+client_id+"&scope="+scopes+"&state="+this.uae_param+"&redirect_uri="+this.uaepass_redirect_url+"&acr_values=urn:safelayer:tws:policies:authentication:level:low&ui_locales=en";
          window.location.href = uae_url;
        },
        logInWithApple() {
            window.AppleID.auth.init({
              clientId: "com.app.naqqe.user", // This is the service ID we created.
              scope: "name email", // To tell apple we want the user name and emails fields in the response it sends us.
              redirectURI: this.apple_redirect_url, // As registered along with our service ID
              state: "origin:web", // Any string of your choice that you may use for some logic. It's optional and you may omit it.
              usePopup: true, // Important if we want to capture the data apple sends on the client side.
            });
              window.AppleID.auth.signIn().then((data) => {
                 if(data.authorization !== undefined){
                     var token = data.authorization.id_token;
                     var user_details = jwt_decode(token);
                     this.social_user.provider_verification = user_details.sub;
                     this.social_user.provider_type = "apple";
                     this.social_user.email = user_details.email;
                      if(data.user !== undefined){
                        if(data.user.name !== undefined){
                          var name_details = data.user.name;
                          var first_name = (name_details.firstName !== undefined)? name_details.firstName : "";
                          var last_name = (name_details.lastName !== undefined)? name_details.lastName : "";
                          var name = first_name+" "+last_name;
                          this.social_user.name = name;
                        }
                      }
                     this.socialLogin();
                 }
            })
            .catch((error) => {
              console.log(error);
              this.$swal({icon: 'error', title: "Error Occured. Please Try Again",background:'#cb1111',color:'#fff'});
            });
        }
    }
}
</script>


