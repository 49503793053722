import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import SignupView from '../views/SignupView.vue'
import DashboardView from '../views/DashboardView.vue'
import Auth from '../resources/Auth.js'


const routes = [
  {
    path: '/',
    name: 'home',
    component: DashboardView,
    meta: {
            requiresAuth: true
    },
  },
  {
    path: '/search/:therapy_id',
    name: 'search_therapist',
    component: () => import('../views/SearchTherapistView.vue'),
    meta: {
            requiresAuth: true
    },
  },
  {
    path: '/session/therapist/:appointment_id',
    name: 'found_therapist',
    component: () => import('../views/TherapistFoundView.vue'),
    meta: {
            requiresAuth: true
    },
  },
  {
    path: '/session/call/:appointment_id',
    name: 'call_page',
    component: () => import('../views/SessionCallView.vue'),
    meta: {
            requiresAuth: true
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/guest/session/:code',
    name: 'guest_session',
    component: () => import('../views/GuestCallView.vue'),
  }, 
  {
    path: '/signup-profile',
    name: 'signup_profile',
    component: () => import('../views/ProfileView.vue'),
    meta: {
            requiresAuth: true
    },
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import('../views/WelcomeView.vue'),
    meta: {
            requiresAuth: true
    },
  },
  {
    path: '/guest/call/:code',
    name: 'guest_call_session',
    component: () => import('../views/GuestCallAttendView.vue'),
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignupView
  },
  {
    path: '/forgotpassword',
    name: 'forgot_password',
    component: () => import(/* webpackChunkName: "about" */ '../views/ForgotPasswordView.vue'),
  },
  {
    path: '/sessions',
    name: 'sessions',
    component: () => import(/* webpackChunkName: "about" */ '../views/SessionView.vue'),
    meta: {
            requiresAuth: true
    },
  },
  {
    path: '/articles',
    name: 'articles',
    component: () => import(/* webpackChunkName: "about" */ '../views/ArticleView.vue'),
    meta: {
            requiresAuth: true
    },
  },
  {
    path: '/article/:id',
    name: 'article_detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/ArticleDetailsView.vue'),
    meta: {
            requiresAuth: true
    },
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import(/* webpackChunkName: "about" */ '../views/WalletView.vue'),
    meta: {
            requiresAuth: true
    },
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import(/* webpackChunkName: "about" */ '../views/SavedPaymentsView.vue'),
    meta: {
            requiresAuth: true
    },
  },
  {
    path: '/refer',
    name: 'refer',
    component: () => import(/* webpackChunkName: "about" */ '../views/ReferFriendView.vue'),
    meta: {
            requiresAuth: true
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import(/* webpackChunkName: "about" */ '../views/NotificationsView.vue'),
    meta: {
            requiresAuth: true
    },
  },
  {
    path: '/refund',
    name: 'refund',
    component: () => import(/* webpackChunkName: "about" */ '../views/RequestRefundView.vue'),
    meta: {
            requiresAuth: true
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "about" */ '../views/FaqView.vue'),
    meta: {
            requiresAuth: true
    },
  },
  {
    path: '/termservices',
    name: 'terms_and_services',
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsOfServiceView.vue'),
    meta: {
            requiresAuth: true
    },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyPolicyView.vue'),
    meta: {
            requiresAuth: true
    },
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutUsView.vue'),
    meta: {
            requiresAuth: true
    },
  },

  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "about" */ '../views/AccountSettingsView.vue'),
    meta: {
            requiresAuth: true
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  
    if (to.matched.some(record => record.meta.requiresAuth) ) {
        if (Auth.check()) {
            next();
            return;
        } else {
            router.push('/login');
        }
    } else {
      if((to.name == 'login' || to.name == 'signup') && Auth.check()){
            router.push('/');
      }else{
       next();
      }
    }
});

export default router
