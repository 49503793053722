//import axios from 'axios';
class Constant {
	getbaseurl () {
		return process.env.VUE_APP_API_URL;
	}
	getFacebookId() {
		return "1151227395620154";
	}
	getFaceBookClientSecret(){
		return "3ecd0325e80c89aa951d0c6b0bac325a";
	}
	getGoogleClientId(){
		return "143231180871-3d3udd4v1dk5rot5v6robhismbdonotd.apps.googleusercontent.com";
	}
	getGoogleClientSecret(){
		return "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";
	}
}
export default new Constant();