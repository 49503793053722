<template>
<footer class="page-foter">
  <div class="custom-container">
    <div class="page-foter-inner">
    <p>Copyright Naqqe©{{getDate()}}. All Rights Reserved.</p>
    <ul>
      <li><a href="#"><img class="crop-tool" src="../assets/images/ft-fb.svg" alt=""></a></li>
       <li><a href="#"><img class="crop-tool" src="../assets/images/twit.svg" alt=""></a></li>
        <li><a href="#"><img class="crop-tool" src="../assets/images/insta.svg" alt=""></a></li>
    </ul>
  </div>
  </div>
</footer>
  <!--log-out--modal-->
<div class="modal fade account-delete" id="log-out" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
      </div>
      <div class="modal-body log-out-user">
        <div class="succes-modal">
          <img src="../assets/images/logout-user.png" alt="">
          <h4>Logging out?</h4>
          <div class="log-out-btn">
            <a href="#" data-bs-dismiss="modal" >No</a>
            <a class="gr-btn" href="#" data-bs-dismiss="modal" @click.prevent="logout">Yes</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
 <script>
import Auth from '../resources/Auth.js';
export default {
    methods: {
        logout() {
            Auth.logout(); //set local storage
            this.$router.push('/login');
        },
        notification() {
            this.$router.push('notifications');
        },
        getDate(){
            return new Date().getFullYear();
        }
    }
}
</script>


