<template>
  <div class="home">
    <HeaderPage/>
    <div class="dashboad-page">
    <div class="custom-container">
      <div class="dashboad-page-wrapper">
         <SidebarPage/>
         <div class="right-bar">
          <div class="row">
              <MainPage :is_search="is_search" @search-cancelled = "searchCancelled"/>
              <ArticlePage/>
          </div>
        </div>
        </div>
      </div>
  </div>
  <FooterPage/>
  </div>
</template>
<script>
// @ is an alias to /src
import HeaderPage from '@/components/HeaderPage.vue'
import ArticlePage from '@/components/ArticlePage.vue'
import SidebarPage from '@/components/SidebarPage.vue'
import MainPage from '@/components/MainPage.vue'
import FooterPage from '@/components/FooterPage.vue'

export default {
  name: 'DashboardView',
  props:['is_search'],
  emits:['searchCancelled'],
  components: {
    HeaderPage,SidebarPage,MainPage,FooterPage,ArticlePage
  },
  methods:{
    searchCancelled(){
      this.$emit('searchCancelled',true);
    }
  }
}
</script>
